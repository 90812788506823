import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = firebase.initializeApp ({
    apiKey: "AIzaSyB_3IGhSUbSHc_mcskcpjGG7ruCTkezM_M",
    authDomain: "test-9218c.firebaseapp.com",
    projectId: "test-9218c",
    storageBucket: "test-9218c.appspot.com",
    messagingSenderId: "664519596348",
    appId: "1:664519596348:web:f5b2c17cb9960f42aee639",
    measurementId: "G-YSYKNS0S7C"
  });

  export default firebaseConfig